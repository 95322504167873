body{
	color:#230D7B;
}
.app{
	background-image: url("./assets/rainbow-bg.svg");
	background-position: center center;
	background-repeat: none;
	background-size:135%;
	min-height: 100%;
	width:100%;
	display:flex;
}

.sideBar{
	width:25%;
	max-width:350px;
	border-right:1px solid var(--lightPurple);
}


.mainPane{
	height: auto;
  justify-content: center;
  display: flex;
  width: 75%;
 	align-items: center; 
}

.mainPane.fullWidth{
	display:block;
	width:100%;
}

/* ---------- SMALL HEIGHT SCREENS ----- */
@media only screen and (max-height: 940px) {
	.mainPane{ 
		padding:60px 0;
	}
}


/* ----------- MOBILE ------------------- */
@media only screen and (max-width: 1145px) {
	.sideBar{
		display:none;
	}
	.mainPane{
		width:100%;
		padding:25px;
		display:flex;
		max-width: 550px;
    margin: 0 auto;
	}
	.mainPane.pagePreview{
		padding:0;
	}

	.mainPane.fullWidth{
		max-width:100%;
	}
}

.mediumBreakpointLogo{
	display:none;
}
@media only screen and (max-width: 1145px) and (min-width: 870px) {
	.mediumBreakpointLogo{
		max-width:110px;
		display:block;
		position:fixed;
		top:20px;
		left:20px;
	}
}

