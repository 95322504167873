
/* Loading elipsis */
.loadingElipsisContainer{
	position:relative;
	opacity:0.5;
	margin-left:5px;
}
.loadingElipsisContainer span{
	position:absolute;
}
.loadingElipsisContainer span:nth-child(1){
	left:0px;
}
.loadingElipsisContainer span:nth-child(2){
	left:10px;
}
.loadingElipsisContainer span:nth-child(3){
	left:20px;
}