
.container{
	width:100%;
  height: calc(100vh - var(--navigationHeight));
  top:var(--navigationHeight);
  left:0;
  background:rgba(35,13,123, 0.8);
}

.modal{
	position:fixed;
  top:92px;
  right:20px;
	z-index: 10;

  max-width:520px;
  margin:0 auto;
  padding:35px 55px;

 	background-image: url("./../../assets/rainbow-bg.svg");
	background-position: center center;
	background-repeat: none;
	background-size:135%;
}

.header{
	display:flex;
	align-items: center;
	margin-bottom:25px;
}

.header img{
	max-width:110px;
	margin-right:20px;
}

.note{
	font-style: italic;
}

.text p{
	margin-bottom:15px;
}
.text{
	margin-bottom: 30px;
}


@keyframes softBounce {
    0%   { transform: scale(1,1)    translateY(0); }
    10%  { transform: scale(1.1,.95) translateY(0); }
    30%  { transform: scale(.95,1.1) translateY(-8px); }
    50%  { transform: scale(1,1)    translateY(0); }
    57%  { transform: scale(1,1)    translateY(-2px); }
    64%  { transform: scale(1,1)    translateY(0); }
    100% { transform: scale(1,1)    translateY(0); }
}

.dottedLine{
	top: 22px;
	position: absolute;
	right: 68px;
  animation-name: softBounce;
  animation-timing-function: ease;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

/* Mobile  */
@media only screen and (max-width: 1145px) {
	.modal{
/*		display:block;*/
/*		position:static;*/
		padding:35px;
	}
	.header img{
		max-width:60px;
	}

}