.copyToClipboard{
	border-radius: 5px;
	display:flex;
	align-items: center;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}


.copyToClipboard button{
	padding:13px;
  margin-left: 9px;
}

.copyToClipboard input{
	color: #230D7B;
	font-size:18px;
	border:none;
	outline:none;
	min-width: 320px;
}

.copyToClipboard.showCopiedMsg{
	background: #BAF1F1;
	justify-content: center;
	padding: 9px 20px;
	border:none;
	height:100%;
}

.copyToClipboard.showCopiedMsg p{
  text-align: center;
	color:#230D7B;
	font-size:18px;
	font-weight: 500;
}