.container{
	display:block !important;
	position:relative !important;
	height:8px !important;
	background: #D9DDED !important;
	border-radius: 3px !important;
}

.loaderBar{
/*	background: linear-gradient(90deg, #0BDAC9 0%, #7366FF 100%) !important;*/
}