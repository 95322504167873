:root {
  --eggplant: #230D7B;
  --lightPurple: #F0EFFF;
  --purple: #716BF1;
  --grey: #47535D;
  --breakpoint: 1024px;
}

#root{
	height:100%;
}

/* Typography */

@font-face {
  font-family: "calibre";
  src: url("./assets/fonts/CalibreWeb-Regular.eot");
  src: url("./assets/fonts/CalibreWeb-Regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/CalibreWeb-Regular.woff2") format("woff2"),
    url("./assets/fonts/CalibreWeb-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "calibre";
  src: url("./assets/fonts/CalibreWeb-Medium.eot");
  src: url("./assets/fonts/CalibreWeb-Medium.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/CalibreWeb-Medium.woff2") format("woff2"),
    url("./assets/fonts/CalibreWeb-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "calibre";
  src: url("./assets/fonts/CalibreWeb-Semibold.eot");
  src: url("./assets/fonts/CalibreWeb-Semibold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/CalibreWeb-Semibold.woff2") format("woff2"),
    url("./assets/fonts/CalibreWeb-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "calibre";
  src: url("./assets/fonts/CalibreWeb-Bold.eot");
  src: url("./assets/fonts/CalibreWeb-Bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/CalibreWeb-Bold.woff2") format("woff2"),
    url("./assets/fonts/CalibreWeb-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Calibre", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1.kl-heading, h2.kl-heading, h3.kl-heading, .kl-heading{
  color: var(--eggplant);
}
span.kl-text{
  color: var(--eggplant);
}

/* Kaleidoscope over-rides */

/* Fix for when you add elevation to textarea */
.text-input__input-container{
  line-height: 0 !important;
}
.text-input{
  width:100%;
}

/* Make buttons new brand colours */
button.button--primary {
  background:var(--eggplant) !important;
}

/* Make text inputs be new brand colors */
input, textarea{
  color:var(--eggplant) !important;
  font-weight:500 !important;
}

/* TEMP Need to duplicate the Kaleidoscope Button component because it depends on Mobx
   and we're not using Mobx for this project             ------------------------- */
button, button.button{
  font-weight:600;
  cursor:pointer;
  font-size:18px;
  padding:20px;
  border:none;
  font-family: "Calibre";
  border-radius:4px;
  transition-property: color, background, outline, box-shadow;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.24, 0.01, 0.42, 0.98);
}

button:hover, button.button:hover{
  box-shadow:0 0 0 3px rgba(0, 133, 123, 0.25);
}

button.primary{
  background:var(--eggplant);
  color:#fff;
}

button.secondary{
	color: #47535d;
  background-color: white;
  box-shadow: inset 0 0 0 1px rgba(129,162,178,.25);
}
/* END TEMP -------------- */
