.header{
	margin-bottom:50px;
}
.subHeading{
	opacity:0.7;
}

.textInput input{
	padding: 15px !important;
	font-weight:400 !important;
	color:var(--eggplant) !important;
}

.textInput{
	width:100%;
}

.actionContainer{
	display: flex;
	margin-top:30px;
	justify-content: flex-end;
}

.button{
	margin-left:20px;
}


.draggableListItem{
	margin-bottom:10px;
	display:flex;
	align-items: center;
}
.dragHandle{
	padding:10px;
		cursor: move; /* fallback if grab cursor is unsupported */
		cursor: grab;
		cursor: -moz-grab;
		cursor: -webkit-grab;
}


.dragOverlayItem{
	background:#fff;
	padding:13px;
	width:100%;
	border-radius:6px;
	background:var(--eggplant);
	font-weight: 500;
	padding-left:40px;
	color:#fff;
}


.loader{
	display: flex;
	flex-direction: column;
	align-items: center;
}

.loaderText{
	margin-top:15px;
}

.mobileScrollFix{
	touch-action: none;
}

/* ----------- MOBILE ------------------- */
@media only screen and (max-width: 1145px) {
	.loader{
		display: flex;
    align-items: center;
    height: 80vh;
    justify-content: center;
	}

	.draggableListItem, .mobileScrollFix{
		touch-action: none;
	}
}