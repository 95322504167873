.spinnerContainer{
  border-radius: 300px;
  position: relative;
  width: 200px;
  height: 200px;

}
.loadingSpinner, .qwilrArrow{
  position:absolute;
  top:0;
  left:0;
}

.loadingSpinner{
  width:100%;
  height:100%;
  z-index: 1;
}

.qwilrArrow{
  z-index: 2;
  left:50%;
  top:50%;
  margin-left:-32px;
  margin-top:-33px;
}

.loader {
  inset: 0;
  transition: opacity 400ms cubic-bezier(0.4, 0, 0.2, 1) 200ms;
  width:200px;
}

.loader svg{
  scale: 1.05;
}

.loader-main {
  display: grid;
  place-items: center;
  place-content: center;
}

@keyframes loaderSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingSpinner path {
  transform-origin: center;
  animation: loaderSpin 1.5s linear infinite;
}


@media only screen and (max-width: 1145px) {
	.spinnerContainer{
		width:130px;
		height:130px;
	}

	.loader{
		width:130px;
	}

	.qwilrArrow{
		margin-left: -26px;
    margin-top: -34px;
    max-width: 50px;
	}
}