.descriptionContainer{
	max-width:477px;
	padding-bottom:50px;
}

.header h2{
	font-size: 44px;
}

.inputs{
	margin-top:60px;
}


.inputRow{
	margin-bottom:31px;
	display:flex;
	align-items: center;
	position:relative;
}

.inputContainer{
	width:100%;
}

.inputRow .inputLabel{
	font-size:28px;
	font-weight:500;
	min-width: 160px;
	text-align: right;
	padding-right: 24px;
	position:absolute;
	left:-160px;
}

/* 
	@HACK - We can't wrap a <Card> around <FormTextInput> because
	when validation errors appear they make the Card background
	look wonky - so we have to add the Card shadows manually.
*/
.inputRow input, .inputRow textarea{
	box-shadow: 0 -1px 0 0 rgba(129, 162, 178, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08), 0 20px 36px -10px rgba(36, 44, 57, 0.1), 0 0 10px rgba(0, 0, 0, 0.06), 0 8px 20px -6px rgba(0, 0, 0, 0.06);
}

.inputContainer{
	position:relative;
/*	max-width: 480px;*/
}

.actionContainer{
	display:flex;
}

.button{
	margin-left:auto;
}


/* templates */
.templates{
/*	display:flex;
	justify-content: center;*/
	margin-top:10px;
}

.templateList{
	display:flex;
	gap:12px;
}
.template{
	cursor:pointer;
	padding:10px;
	border:1px solid #9397C9;
	border-radius:5px;
}
.template:hover{
	background:var(--lightPurple);
}

.templateSentence{
	font-size:24px;
/*	opacity:0.6;*/
	max-width:560px;
	line-height: 1.5;
	color:#7769AF;
}
.templateSentenceItem{
	font-weight:500;
	background:#E9E5F4;
	padding:2px 5px;
	border-radius: 4px;
	cursor:pointer;
}



/* Kaleidoscope overrides */

.inputRow input, .inputRow textarea{
	font-size:28px !important;
	padding:25px !important;
}

.inputRow textarea{
	height:180px !important;
	min-height:180px !important;
}

.mobileVideo{
	display: none;
}


/* ----------- MOBILE ------------------- */
@media only screen and (max-width: 1145px) {
	.inputRow{
		position:static;
		display:block;
	}	
	.inputRow .inputLabel{
		position: static;
		margin-bottom: 9px;
    display: block;
    text-align: left;
	}

	.templateSentence{
		font-size:20px;
	}

	.inputs{
		margin-top:30px;
	}

	.mobileVideo{
		display: block;
		margin-top:40px;
		border-top:1px solid var(--lightPurple);
		padding-top:80px;
		display:flex;
		justify-content: center;
	}

}