.sidebar{
	height:100%;
	display: flex;
	flex-direction: column;
}

.mainWrapper{
	padding:55px;
}

.header{
	margin-bottom:40px;
}

.stepList{
	margin-left:29px;
}


.stepLabel{
	font-size:20px;
	padding-bottom:50px;
	padding-left:30px;
	border-left:1px dashed #AFB4D8;
	position:relative;
}
.stepLabel:last-child{
	border-left:none;
}

.stepIndicator{
	width:22px;
	height:22px;
	border-radius: 22px;
	background: var(--lightPurple);
	display:block;
	position:absolute;
	left:-11.5px;
}
.stepName{
	cursor:pointer;
	opacity:0.4;
}

.currentStep{
}

.currentStep .stepName{
	color:var(--eggplant);
	opacity: 1;
	font-weight:500;
}

.currentStep .stepIndicator{
	background:var(--purple);
	box-shadow: 0 0 15px rgba(0,0,0,0.1);
}

