/* Video  */
.videoContainer{
  position: fixed;
  z-index: 1000;
  background: rgba(36, 44, 57, 0.93);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.videoContainer iframe{
  position: absolute; top: 0; left: 0; width: 100%; height: 100%;
}

.videoSizing{
  width: 70vw;
  border-radius: 16px;
}
.videoResponsiveContainer{
  position: relative; 
  padding-bottom: 56.25%; 
  height: 0;
  background: linear-gradient(241.29deg, #A299FF 34.34%, #7469EF 84.48%);;
  box-shadow:  inset 0 1px 0 0 rgba(129, 162, 178, 0.1), 0 4px 24px rgba(0, 0, 0, 0.1), 0 40px 80px -24px rgba(0, 0, 0, 0.3), 0 1px 4px rgba(0, 0, 0, 0.16), 0 20px 50px -16px rgba(0, 0, 0, 0.3)
}

.closeVideo{
  padding: 20px;
  border-radius: 4px;
  color: #fff;
  transition:  color 0.2s ease;
  cursor: pointer;
  font-size: 40px;
  font-weight: 500;
  background: #3B414C;
  margin-left: auto;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.closeVideo:hover{
  color: #fff;
}