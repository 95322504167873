.progressContainer{
/*	display: flex;
	align-items: center;
	justify-content: center;*/
}

.contentWrapper{

}

.progressLabel{
	margin-bottom:15px;
}
.progressLabel span{
	opacity:0.7;
}

.tick{
	width:25px;
	opacity: 0.8;
}

.loadingCard{
	padding:22px;
	min-width: 400px;
	margin-bottom:10px;
	display:flex;
	align-items: center;
	max-width:470px;
	background:rgba(256,256,256,0.4);
}

.sectionName{
	opacity:0.6;
	margin-bottom:7px;
}
.sectionName span{
	font-weight: 500 !important;
}

.loadingCard.loaded{
	background:#fff;
	min-height:94px;
}

.loadingCard.loaded .sectionName{
	opacity:1;
}

.main{
	margin-left:20px;
	width:100%;
	border-radius:3px;
}

.spinner svg{
	color:#9397C9;
}


/* Mobile  */
@media only screen and (max-width: 1145px), only screen and (max-height: 900px){
	.progressLabel{
		display:none;
	}
	
	.loadingCard{
		min-width: 100%;
	}	
}

