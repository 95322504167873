.errorWrapper{
	position:fixed;
	z-index:99999999999999;
	top:0;
	left:0;
	height:100%;
	width:100%;
	background-color:#fff;
	background-image: url("./../assets/rainbow-bg.svg");
	background-position: center center;
	background-repeat: none;
	background-size:135%;
	display:flex;
	align-items: center;
  justify-content: center;
}

.error{
	text-align: center;
	max-width:450px;
	margin-top:-50px;
}

.error img{
	margin:0px auto 50px;
}

.error p{
	margin-top:16px;
	margin-bottom:16px;
}