.loadingContainer{
	display: flex;
	align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.rightPane, .leftPane{
	padding:40px;
	width:50%;
}

.leftPaneContentWrapper, .rightPaneContentWrapper{
	max-width:550px;
}

.leftPane{
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

.rightPane{
	border-left:1px solid #DAD6EC;
	height:90%;
	display: flex;
	align-items: center;
	justify-content: center;
}




/* ----------- MOBILE ------------------- */
.mobileVersion{
	display:none;
}
@media only screen and (max-width: 1145px), only screen and (max-height: 900px){
	.loadingContainer{
		display:block;
		padding-bottom:50px;
	}

	.rightPane, .leftPane{
		display:none;
	}
	.mobileVersion{
		display: block;
		padding-bottom:40px;
	}
}