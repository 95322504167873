.pagePreview{
	--navigationHeight: 87px;
}

.header{
	display: flex;
	align-items: center;
	height:var(--navigationHeight);
	padding:20px;
	box-shadow: 0 0 0 rgba(0,0,0,0.1);
	border-bottom:1px solid var(--lightPurple);
}

.logoLink{
	margin-right: auto;
	max-width:114px;
}

.actions{
	margin-left:auto;
	display: flex;
}

.actions button{
	height: 100%;
}

.editButton{
	margin-left:15px;
	padding:13px;
}

.containerContainer{
	width:100%;
  height: calc(100vh - var(--navigationHeight));
}

.frame{
	width:100%;
  height: calc(100vh - var(--navigationHeight));
}

.confettiWrapper{
	position: absolute;
	top: 186px;
	right: 484px;
}

/* Mobile  */
@media only screen and (max-width: 971px) {
	.tryAgainButton{
		display:none;
	}
}
@media only screen and (max-width: 760px) {
	.copyURL{
		display:none;
	}
}