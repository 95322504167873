.explainer{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
}


.loadingText{
	margin-top:30px;
}

.loadingExplainer{
	font-size: 24px;
	font-weight:500;
	margin-top:15px;
	opacity: 0.5;
}

.productExplainer{
	background:#F0F4F9;
	border:1px solid rgba(35,13,123,0.22);
	max-width: 515px;
	margin:0 auto;
	margin-top:50px;
	padding:25px;
	font-size:22px;
	font-weight:400;
	border-radius: 10px;
}


.mobileInsert{ display: none; }
@media only screen and (max-width: 1145px), only screen and (max-height: 900px) {
	.mobileInsert{ 
		display: block;
		margin-top:20px;
	}
	.header{
		text-align: center;
	}
	.productExplainer{
		text-align: center;
		margin-top:40px;
	}
}