
/* Video */

.tourVideo{
  margin-top:auto;
  margin-bottom:25px;
  align-self: center;
  border-radius: 8px;
	background: #EEF5FD;
	width:100%;
	position:relative;
	min-height: 165px;
	max-width:85%;
	cursor: pointer;
	transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
	outline: 2px solid transparent;
	box-shadow: 0px 7.93808126449585px 19.845203399658203px -5.953561782836914px rgba(0, 0, 0, 0.06), 0px 0px 9.922601699829102px 0px rgba(0, 0, 0, 0.06), 0px 19.845203399658203px 35.721370697021484px -9.922601699829102px rgba(18, 20, 35, 0.10), 0px 0.9922601580619812px 2.976780891418457px 0px rgba(0, 0, 0, 0.08), 0px -0.9922601580619812px 0px 0px rgba(224, 232, 241, 0.50);
}
.tourVideo:hover {
	background: #E3EEFC;
	outline:2px solid #D5E2F2;
}

.tourVideo:hover .playbutton path{
	fill: #AFB4D8;
}

.videoTitle{
	position:absolute;
  top: 15px;
  left: 15px;
  font-size: 116%;
  line-height: 1.15;
}

.videoTitle h3{
	font-weight: 500;
}

.tanner{
	position:absolute;
  bottom: 0;
  right: 8px;
}
.playButton{
	position:absolute;
	z-index:5;
	width: 25%;
	height: 25%;
	top: 50%;
	left: 50%;
	margin-left: -12.5%;
	margin-top: -12.5%;
	max-width:100px;

}
.playButton svg circle{
	box-shadow: 0px 7.93808126449585px 19.845203399658203px -5.953561782836914px rgba(0, 0, 0, 0.06), 0px 0px 9.922601699829102px 0px rgba(0, 0, 0, 0.06), 0px 19.845203399658203px 35.721370697021484px -9.922601699829102px rgba(18, 20, 35, 0.10), 0px 0.9922601580619812px 2.976780891418457px 0px rgba(0, 0, 0, 0.08), 0px -0.9922601580619812px 0px 0px rgba(224, 232, 241, 0.50);
}