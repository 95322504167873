.header{
	margin-bottom:35px;
}

.styleList{
	display:flex;
	gap: 45px;
}

.styleItem{
	cursor:pointer;
	text-align: center;
}

.styleTile{
}

.styleTile img{
	grid-area:1/1;
	z-index:1;
	border-radius:7px;
}

.styleTile.selectedStyle{
	transition: all 0.15s ease-in-out;
	transform: scale(1.01);
}

.tileContent{
	grid-area:1/1;
	z-index:2;
	display:flex;
	height:100%;
	width:100%;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.actionContainer{
	display: flex;
	margin-top:30px;
}

.button{
	margin:60px auto 0;
}

.styleName{
	margin:0 auto;
  display: inline-block;
  padding: 5px 5px;
  margin-top: 24px;
}

.styleName.selectedStyle{
	border-bottom:solid 6px rgba(35,13,123, 0.21);
}


/* ----------- MOBILE ------------------- */
@media only screen and (max-width: 1145px) {
	.styleList{
		flex-direction: column;
		align-items: center;
	}
	.actionContainer .button{
		margin:auto;
	}

	.actionContainer{
		margin-top:60px;
	}

	.header{
		text-align: center;
	}
}